<template>
  <div>
    <div class="settings-container" v-if="!!currentItem">
      <div class="d-flex justify-content-between align-items-center">
        <!--  versions display and additional info(left-section)   -->
        <img src="/img/icons/info-icon.svg"
             v-if="!canModify"
             v-b-tooltip.hover.html
             :title="editInfo"
        >
        <div type="button"
             :id="`playbook-items-versions-${currentItem.id}-${index}`"
             class="font-12"
             v-if="canModify"
        >
          {{ staticText.versionsBtnLabel }}
        </div>
        <b-popover
          :target="`playbook-items-versions-${currentItem.id}-${index}`"
          placement="top"
          triggers="hover"
          boundary="viewport"
          custom-class="bao-popover"
        >
          <div class="versions-container">

            <div class="versions-list-head">
              {{
                currentItem.versions.length > 0 ? staticText.itemVersionLabel : staticText.noVersionsAvailableMessage
              }}
            </div>
            <div class="versions-list">
              <b-list-group v-for="version in currentItem.versions"
                            :key="version.id"
              >
                <b-list-group-item
                  button
                  :class="['no-border', 'p-1', 'mt-1', {'bg-slate-08': version.id===currentItem.currentVersionId}]"
                  @click="handleVersionSelected(currentItem, version.id)"
                >
                  {{ version.created_at }}
                </b-list-group-item>

              </b-list-group>
            </div>

          </div>
        </b-popover>

        <!-- delete button and other setting flags (right-section)   -->
        <div class="d-flex justify-content-between align-items-center ml-auto">

          <!--  relevant for text-match flag   -->
          <div
            class="flag-container border-right-slate06"
          >
            <label :for="`relevant-for-text-match-202325071717-${currentItem.uniqueId}`"
                   class="ml-auto mb-0 cursor-pointer font-12"
            >
              {{ staticText.relevantForTextMatchLabel }}
            </label>
            <b-form-checkbox
              :id="`relevant-for-text-match-202325071717-${currentItem.uniqueId}`"
              class="ml-2 mr-n2"
              :checked="currentItem.relevant_for_text_match"
              switch
              v-b-tooltip.hover.html
              :disabled="!canModify"
              :title="getRelevantForTextMatchTooltipText"
              @change="setRelevantForTextMatchFlag(currentItem)"
            ></b-form-checkbox>
          </div>

          <!--  relevant for custom summary flag   -->
          <div
            class="flag-container border-right-slate06"
          >
            <label :for="`relevant-for-custom-summary-202325071717-${currentItem.uniqueId}`"
                   class="ml-auto mb-0 cursor-pointer font-12"
            >
              {{ staticText.relevantForCustomSummaryLabel }}
            </label>
            <b-form-checkbox
              :id="`relevant-for-custom-summary-202325071717-${currentItem.uniqueId}`"
              class="ml-2 mr-n2"
              :checked="currentItem.relevant_for_custom_summary"
              switch
              v-b-tooltip.hover.html
              :disabled="!canModify"
              :title="getRelevantForCustomSummaryTooltipText"
              @change="setRelevantForCustomSummaryFlag(currentItem)"
            ></b-form-checkbox>
          </div>

          <!--  item required flag   -->
          <div
            v-if="showRequiredFlag"
            class="flag-container border-right-slate06"
          >
            <label :for="`required-item-202102192009-${currentItem.uniqueId}`"
                   class="ml-auto mb-0 cursor-pointer font-12"
            >
              {{ staticText.required }}
            </label>
            <b-form-checkbox
              :id="`required-item-202102192009-${currentItem.uniqueId}`"
              class="ml-2 mr-n2"
              :checked="currentItem.required"
              switch
              v-b-tooltip.hover.html
              :disabled="!canModify"
              :title="getRequiredTooltipText"
              @change="setRequiredFlag(currentItem)"
            ></b-form-checkbox>
          </div>

          <!--  other setting options   -->
          <b-btn :id="`playbook-items-options-${currentItem.id}-${index}`"
                 v-if="canModify"
                 class="options-btn mx-2"
          >
            <img src="@/assets/svgs/three-dot-btn.svg">
          </b-btn>

          <b-popover
            :target="`playbook-items-options-${currentItem.id}-${index}`"
            placement="top"
            triggers="hover"
            boundary="viewport"
            custom-class="bao-popover popover-wide"
          >
            <b-list-group class="popover-options-container">
              <!--  show notes field checkbox   -->
              <b-list-group-item
                :id="`note-checkbox-${currentItem.uniqueId}`"
                class="d-flex no-border p-1 mt-1 align-items-center justify-content-between"
                button
                @click.prevent="setNoteFlag(currentItem)"
              >
                {{ staticText.addNotesCheckboxLabel }}
                <b-form-checkbox
                  class="ml-2 mr-n2"
                  switch
                  :checked="currentItem.note"
                >
                </b-form-checkbox>
              </b-list-group-item>

              <!--  show end conversation button checkbox   -->
              <b-list-group-item
                :id="`ending-item-checkbox-${currentItem.uniqueId}`"
                v-if="!isItemType(currentItem, 'playbookloader')"
                class="d-flex no-border p-1 mt-1 align-items-center justify-content-between"
                button
                @click.prevent="setEndConversationFlag(currentItem)"
              >
                {{ staticText.endingItemCheckboxLabel }}
                <b-form-checkbox
                  class="ml-2 mr-n2"
                  switch
                  :checked="currentItem.ending_item"
                >
                </b-form-checkbox>
              </b-list-group-item>

              <!--  show item collapsed checkbox   -->
              <b-list-group-item
                :id="`collapse-checkbox-${currentItem.uniqueId}`"
                class="d-flex no-border p-1 mt-1 align-items-center justify-content-between"
                button
                @click.prevent="setCollapsedFlag(currentItem)"
              >
                {{ staticText.collapsed }}
                <b-form-checkbox
                  class="ml-2 mr-n2"
                  switch
                  :checked="currentItem.collapse"
                >
                </b-form-checkbox>
              </b-list-group-item>

              <!--  shortcuts on item checkbox   -->
              <b-list-group-item
                v-if="showEnableShortcutFlag(currentItem)"
                :id="`shortcut-on-item-${currentItem.uniqueId}`"
                class="d-flex no-border p-1 mt-1 align-items-center justify-content-between"
                button
                @click.prevent="setObjectionFlag(currentItem)"
              >
                {{ staticText.shortcutFlagCheckboxLabel }}
                <b-form-checkbox
                  class="ml-2 mr-n2"
                  switch
                  :checked="currentItem.objectionFlag"
                >
                </b-form-checkbox>
              </b-list-group-item>
            </b-list-group>
          </b-popover>

          <!-- Delete button -->
          <div v-if="!hideDeleteButton">
            <bao-delete-button
              :id="`playbook-item-delete-202103180129-${currentItem.uniqueId}`"
              :tooltip="staticText.removeItemBtnTooltip"
              tooltip-placement="top"
              :url="getDeleteUrl"
              extra-class="p-0 m-0 bg-transparent"
              icon="fa fa-xs fa-trash global-color-slate-80 p-2"
              class="delete-btn"
              :show-confirm="showDeleteConfirmationModal"
              @delete="handleItemDelete"
              @deleted="handleItemDeleted"
              @error="error => $emit('emit-error', error, currentItem)"
              @modal-shown="checkReferencedDynamicPlaceholders"
            >
              <div v-if="referencedItems.length>0" class="mb-3">
                {{ staticText.itemReferencedWarningMessage }}:
                <div v-for="referencedItem in referencedItems"
                     :key="referencedItem.id"
                >
                  <span class="ml-3">- {{ referencedItem.name }}</span>
                </div>
              </div>
              <div>{{ getDeleteMessage }}</div>
            </bao-delete-button>
          </div>
        </div>
      </div>
    </div>
    <progress-indicator v-if="!currentItem"></progress-indicator>

    <!-- Modal to show warning on toggle shortcuts on item-->
    <b-modal
      ref="toggle-shortcuts-on-item-warning-modal-202303271506"
      id="toggle-shortcuts-on-item-warning-modal-202303271506"
      :title="staticText.navigationModalTitle"
    >
      <template v-slot:modal-header-close>
        <img src="../../../../public/img/icons/close-icon.svg"/>
      </template>
      <b-alert variant="warning" show>
        <p class="m-0">
          {{ staticText.changingPlaybookWarningText }}
        </p>
      </b-alert>
      <template #modal-footer>
        <button
          class="btn btn-secondary"
          @click="$refs['toggle-shortcuts-on-item-warning-modal-202303271506'].hide()"
        >
          {{ staticText.cancelLabel }}
        </button>
        <button
          class="btn btn-primary"
          @click="toggleObjectionFlagAndDeleteObjections(currentItem)"
        >
          {{ staticText.continueLabel }}
        </button>
      </template>
    </b-modal>
  </div>

</template>

<script>
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import { playbookServices } from "@/apps/talkscript/PlaybookServices"
import { baoDelayService } from "@/apps/call"
import axios from "axios"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import { mapGetters } from "vuex"

export default {
  name: "PlaybookItemSettings",
  data () {
    return {
      referencedItems: [],
      axios,
      staticTextDefault: {
        endingItemCheckboxLabel: "Show “End Conversation” Button",
        endingItemCheckboxTooltip: "Adds the \"End Conversation\" button at the end of this item.",
        addNotesCheckboxLabel: "Show Notes field",
        addNotesCheckboxTooltip: "Adds a notes field to the item.",
        itemSettingsLabel: "Item Settings:",
        shortcutFlagCheckboxLabel: "Shortcuts on this item",
        required: "Required",
        requiredTooltip: "Activate it to make the item required",
        collapsed: "Collapsed",
        collapsedTooltip: "Activate it to collapse the item at the start of the call",
        itemVersionLabel: "Version",
        versionsBtnLabel: "Versions",
        selectItemVersionPlaceholder: "No version available",
        unableToFetchVersion: "Unable to fetch the selected version. ",
        removeItemBtnTooltip: "Click to remove item",
        normalUserEditLibraryItemError: "Only your admin has the permission to edit this item.",
        adminUserEditLibraryItemError: "This item can only be edited in the <a target='_blank' href={{libraryItemUrl}}>library</a>.",
        itemDeleteWarningMessage: "Are you sure you want to delete the item?",
        singleShortcutDeleteMessage: "If you delete this item, the entire shortcut will be deleted.",
        noVersionsAvailableMessage: "No other versions available yet.",
        navigationModalTitle: "Warning",
        cancelLabel: "Cancel",
        continueLabel: "Continue",
        relevantForTextMatchLabel: "Relevant for Text-Match",
        relevantForCustomSummaryLabel: "Relevant for Custom Summary",
        relevantForTextMatchTooltip: "Activate to count this item in for the text-match.",
        relevantForCustomSummaryTooltip: "Activate to use this item to generate the custom summary.",
        changingPlaybookWarningText: "Existing shortcuts will be deleted from this item when turning off the toggle.",
        itemReferencedWarningMessage: "Data from this item is currently re-used in the following item(s)",
        nonEditableItemInfo: "This library item can not be edited."
      }
    }
  },
  components: {
    ProgressIndicator,
    BaoDeleteButton
  },
  props: {
    index: {
      type: Number,
      default: 0,
      required: true
    },
    currentItem: {
      type: Object,
      default: () => {
      },
      required: true
    },
    canModify: {
      type: Boolean,
      default: true,
      required: false
    },
    mainContainer: {
      type: Object,
      default: () => {
      },
      required: false
    },
    baseUrl: {
      type: String,
      required: true
    },
    isOpenFromLibraryItemsModal: {
      type: Boolean,
      default: false,
      required: false
    },
    isOpenFromShortcutsModal: {
      type: Boolean,
      default: false,
      required: false
    },
    rootContainer: { // root container of the playbook items
      type: Object,
      default: () => {
      },
      required: false
    },
    selectedObjection: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      currentUserIsAdminOrSuperuser: "auth/isAdminOrSuperUser"
    }),
    showRequiredFlag () {
      return !(this.isItemType(this.currentItem, "static") || this.isItemType(this.currentItem, "playbookloader"))
    },
    showDeleteConfirmationModal () {
      if (!!this.selectedObjection && !this.isSingleShortcutItem) return false
      return true
    },
    isSingleShortcutItem () {
      return !!this.selectedObjection && this.mainContainer.children.length === 1
    },
    hideDeleteButton () {
      return (
        (!this.canModify && !this.currentItem.is_library_item) ||
        this.isOpenFromLibraryItemsModal
      )
    },
    getDeleteUrl () {
      if (this.selectedObjection) {
        // if currentItem is the only item in a shortcut, delete entire shortcut
        if (this.isSingleShortcutItem && this.selectedObjection.id) return `/api/objectionchoices/${this.selectedObjection.id}`
        // else handle deletion in shortcut modal
        else return null
      }
      // Else, If currentItem is library item and is opened in playbook configurator(checking if mainContainer is available),
      // then de-link item from mainContainer
      if (this.currentItem.is_library_item && this.mainContainer && this.mainContainer.id) {
        return `${this.baseUrl}/${this.mainContainer.id}/de_link_child?child=${this.currentItem.id}`
      }
      return `${this.baseUrl}/${this.currentItem.id}`
    },
    getDeleteMessage () {
      return this.isSingleShortcutItem ? this.staticText.singleShortcutDeleteMessage : this.staticText.itemDeleteWarningMessage
    },
    editInfo () {
      if (this.currentItem.item_type === "crmlink" && this.currentItem.is_library_item) return this.staticText.nonEditableItemInfo
      return this.currentUserIsAdminOrSuperuser ? this.evalString(this.staticText.adminUserEditLibraryItemError) : this.staticText.normalUserEditLibraryItemError
    },
    getRequiredTooltipText () {
      if (!this.canModify) return this.editInfo
      return this.staticText.requiredTooltip
    },
    getRelevantForTextMatchTooltipText () {
      if (!this.canModify) return this.editInfo
      return this.staticText.relevantForTextMatchTooltip
    },
    getRelevantForCustomSummaryTooltipText () {
      if (!this.canModify) return this.editInfo
      return this.staticText.relevantForCustomSummaryTooltip
    },
    libraryItemUrl () {
      return `/itemlibrary?libraryItem=${this.currentItem.id}`
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  },
  methods: {
    ...playbookServices.methods,
    ...baoDelayService.methods,
    checkReferencedDynamicPlaceholders () {
      this.referencedItems = [] // reset referenced items
      const playbookItems = this.rootContainer.children
      const objectionItems = this.rootContainer.objections.flatMap(objection => objection.workflow.children)
      const allItems = playbookItems.concat(objectionItems)
      for (const item of allItems) {
        if (item.display_text) {
          const tempDiv = document.createElement("div")
          tempDiv.innerHTML = item.display_text
          const spanElements = tempDiv.querySelectorAll("span[data-id]")

          for (const spanElement of spanElements) {
            const dataId = spanElement.getAttribute("data-id").slice(2, -2).split(",")
            if (dataId.length >= 3 && dataId[2] === String(this.currentItem.id)) {
              // If the item with the same ID is not present, insert it into referencedItems array
              const isPresent = this.referencedItems.some(obj => obj.id === item.id)
              if (!isPresent) {
                this.referencedItems.push(item)
              }
            }
          }
        }
      }
    },
    setRelevantForTextMatchFlag (item) {
      item.relevant_for_text_match = !item.relevant_for_text_match
      this.$emit("handle-playbook-item-changed", item)
    },
    setRelevantForCustomSummaryFlag (item) {
      item.relevant_for_custom_summary = !item.relevant_for_custom_summary
      this.$emit("handle-playbook-item-changed", item)
    },
    setRequiredFlag (item) {
      item.required = !item.required
      this.$emit("handle-playbook-item-changed", item)
    },
    isItemType (item, type) {
      return item.item_type === type
    },
    setNoteFlag (item) {
      item.note = !item.note
      this.$emit("handle-playbook-item-changed", item)
    },
    setCollapsedFlag (item) {
      item.collapse = !item.collapse
      this.$emit("handle-playbook-item-changed", item)
    },
    setEndConversationFlag (item) {
      item.ending_item = !item.ending_item
      this.$emit("handle-playbook-item-changed", item)
    },
    handleItemDelete () {
      // If item is the only item in the shortcut, delete entire shortcut
      // Else, delete just the item from the shortcut
      if (!this.isSingleShortcutItem) this.$emit("delete-item", this.index)
      else this.$emit("shortcut-deleted")
    },
    handleItemDeleted () {
      if (!this.isSingleShortcutItem) this.$emit("order-was-changed")
    },
    handleVersionSelected (item, versionId) {
      /**
       * This method checks if there are any pending requests to update the item and only if these requests are
       * completed, then it executes the method updateItemToVersion (which updates the item with the version provided)
       *
       * But for objections, there are no pending requests, and we don't update the version until the user saves;
       * so we just emit to the parent so the version is retrieved from backend and shown to user
       */
      if (this.selectedObjection) {
        const emittedObject = {
          item: JSON.parse(JSON.stringify(item)),
          versionId
        }
        this.$emit("handle-version-changed", emittedObject)
        return
      }
      this.$emit("emit-error", null, item)
      this.$set(item, "loading", true)
      return new Promise((resolve, reject) => {
        // if item update request was already sent but not completed then
        // Step 1: wait for it to complete
        // Step 2: wait till all pending request were added to delayed queue so that you have to wait for only one
        // request to complete
        // Step 3: then execute the latest update request and wait for it to complete
        // Step 4: then update the item with new version
        // Following the above steps guarantees all the changes on item are versioned and updates on item are synchronised
        if (item.updateInProgress) {
          item.loadingPromise = item.delayedUpdatePromise.then(() => {
            return Promise.all(item.waitingUpdatePromises).then(() => {
              return this.executeDelayedOpAndUpdateToVersion(item, versionId).then(resolve)
            })
          }).catch(error => {
            reject(error)
          })
        } else {
          // if there is no update operation already executing, then execute any delayed operations(operations waiting
          // for timeout) immediately and then update the item with new version
          item.loadingPromise = this.executeDelayedOpAndUpdateToVersion(item, versionId)
            .then(resolve)
            .catch(error => {
              reject(error)
            })
        }
      })
    },
    executeDelayedOpAndUpdateToVersion (item, versionId) {
      /**
       * Execute any delayed operation pending before updating the item to the version provided
       */
      return new Promise((resolve, reject) => {
        const opId = this.getSaveItemOpId(item)
        this.executeDelayedOperation(opId).then(() => {
          return this.updateItemToVersion(item, versionId).then(resolve)
        }).catch(error => {
          const errorMessage = this.staticText.unableToFetchVersion + this.extractErrorMessage(error)
          this.setError(item, errorMessage)
          reject(errorMessage)
        })
      })
    },
    updateItemToVersion (item, versionId) {
      return new Promise((resolve, reject) => {
        this.axios.put(this.baseUrl + "/" + item.id + "/update_to_version?version=" + versionId)
          .then(response => {
            Object.assign(item, this.transformItem(response.data, this.mainContainer))
            this.$set(item, "settings", true)
            this.$set(item, "loading", false)
            this.$emit("handle-playbook-item-changed", item)
            resolve()
          }).catch(error => {
          // Update the currentVersionId back to old version in the dropdown
            this.$set(item, "currentVersionId", this.getVersionId(item))
            this.$set(item, "loading", false)
            this.$emit("emit-error", error, item)
            reject(error)
          })
      })
    },
    setError (item, error) {
      this.$emit("emit-error", error, item)
    },
    showEnableShortcutFlag (item) {
      return !item.is_objection && !this.isItemType(item, "playbookloader") && !item.is_library_item
    },
    setObjectionFlag (item) {
      if (item.objectionFlag) {
        if (item.objections.length > 0) this.$refs["toggle-shortcuts-on-item-warning-modal-202303271506"].show()
        else this.toggleObjectionFlag(item)
      } else {
        this.toggleObjectionFlag(item)
      }
    },
    toggleObjectionFlag (item) {
      item.objectionFlag = !item.objectionFlag
      this.$emit("handle-playbook-item-changed", item)
    },
    toggleObjectionFlagAndDeleteObjections (item) {
      item.objectionFlag = !item.objectionFlag
      this.$refs["toggle-shortcuts-on-item-warning-modal-202303271506"].hide()
      this.deleteObjections(item)
    },
    deleteObjections (item) {
      const objections = JSON.parse(JSON.stringify(item.objections))
      this.$set(item, "objections", [])
      const objectionDeletionPromises = []
      for (const objection of objections) {
        objectionDeletionPromises.push(this.deleteObjection(objection, item))
      }
      return Promise.all(objectionDeletionPromises).then(() => {
        this.$emit("handle-playbook-item-changed", item)
      }).catch((error) => {
        this.$emit("emit-error", error, item)
      })
    }
  }
}
</script>

<style scoped lang="scss">

.settings-container {
  padding: 12px 24px;
}

.versions-container {
  min-height: 48px;
  border-radius: 12px;
  padding: 6px 6px 12px;

  .versions-list {
    padding: 0 6px 6px;
    max-height: 130px;
    overflow-y: scroll;
  }

  .versions-list-head {
    text-align: left;
    padding: 6px;
  }

  .bg-slate-08 {
    background-color: $slate08;
  }
}

.flag-container {
  padding: 4px 16px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-right-slate06 {
  border-right: 1px solid $slate06;
}

.popover-wide {
  max-width: 320px;

  .popover-options-container {
    padding: 12px;
  }
}

.options-btn,
.delete-btn {
  border-radius: 8px;
  width: 32px;
  height: 32px;
  background-color: transparent;
  transition: 0.3s background-color ease-in;
  align-items: center;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: $slate08;
  }

  .delete-btn {
    padding-top: 2px;
  }
}

</style>
